import {Bootpay} from '@bootpay/client-js';
import './App.css';
import {useEffect, useState} from "react";

interface IInvoice {
  price: number;
  name: string;
  username: string;
  phone: string;
  email: string;
  status: 'UNPAID' | 'PAID' | 'WAITING_FOR_TRANSFER' | 'WAITING_FOR_WIRE_TRANSFER' | 'CANCELED';
}
interface IVbank {
  bank_account: string;
  bank_name: string;
  bank_username: string;
}

const App = () => {
  const [invoice, setInvoice] = useState<IInvoice | undefined>(undefined);
  const [vbankInfo, setvbankInfo] = useState<IVbank | undefined>(undefined);
  const invoiceID = window.location.pathname.substring(1);

  useEffect(() => {
    const loadInvoice = async () => {
      const request = await fetch('//billing.clsu.kr/api/' + invoiceID);
      const json = await request.json();
      if (!json.status) {
        alert(json.message);
        return;
      }

      setInvoice({
        ...json.invoice,
      });
    };

    loadInvoice().then();
  }, [invoiceID]);

  const ProcessPayment = async () => {
    if (!invoice) {
      alert('Invoice not loaded');
      return;
    }

    const payType = (document.querySelector('input[name="payment_method"]:checked') as HTMLInputElement).value;
    if (payType === 'wire_transfer') {
      await fetch('https://billing.clsu.kr/api/' + invoiceID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'no-cors',
        body: JSON.stringify({
          payType: 'WIRE_TRANSFER',
        }),
      });

      setInvoice({
        ...invoice,
        status: 'WAITING_FOR_WIRE_TRANSFER',
      });
    } else {
      const response = await Bootpay.requestPayment({
        application_id: '62e92b89cf9f6d001f7c17e9',
        price: invoice.price,
        order_name: invoice.name,
        order_id: invoiceID,
        user: {
          username: invoice.username,
          phone: invoice.phone,
          email: invoice.email,
        },
      });

      if (response.event === 'done') {
        await fetch('https://billing.clsu.kr/api/' + invoiceID, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'no-cors',
          body: JSON.stringify({
            payType: 'CARD',
          }),
        });
        setInvoice({
          ...invoice,
          status: 'PAID',
        });
      } else if (response.event === 'issued') {
        setvbankInfo(response.data.vbank_data);
        setInvoice({
          ...invoice,
          status: 'WAITING_FOR_TRANSFER',
        });
        await fetch('https://billing.clsu.kr/api/' + invoiceID, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'no-cors',
          body: JSON.stringify({
            payType: 'VBANK',
          }),
        });
      }
    }
  }

  return (
    <>
      <div className={"container"}>
        <div className={"LogoArea"}>cloud/square</div>
        {invoice && (
          <div className={"MainArea"}>
            {invoice.status === 'UNPAID' && (
              <>
                <div className={"MainArea__row"}>
                  <div className={"MainArea__col"}>
                    상품명
                  </div>
                  <div className={"MainArea__col"}>
                    {invoice.name}
                  </div>
                </div>
                <div className={"MainArea__row"}>
                  <div className={"MainArea__col"}>
                    결제금액
                  </div>
                  <div className={"MainArea__col"}>
                    {invoice.price.toLocaleString()}원
                  </div>
                </div>
                <div className={"MainArea__row"}>
                  <div className={"MainArea__col"}>
                    결제방법
                  </div>
                  <div className={"MainArea__col payment_selection"}>
                    <div>
                      <input id={"rdoCard"} disabled={invoice.price >= 1000000} type={"radio"} name={"payment_method"}
                             value={"card"}/>
                      <label htmlFor={"rdoCard"}>신용카드 / 실시간계좌이체 / 가상계좌</label>
                    </div>
                    <div>
                      <input id={"rdoStripe"} disabled={true} type={"radio"} name={"payment_method"} value={"stripe"}/>
                      <label htmlFor={"rdoStripe"}>해외카드결제</label>
                    </div>
                    <div>
                      <input id={"rdoWireTransfer"} defaultChecked={true} type={"radio"} name={"payment_method"} value={"wire_transfer"}/>
                      <label htmlFor={"rdoWireTransfer"}>무통장입금</label>
                    </div>
                    <div className={"PaymentCaution"}>
                      * 1,000,000원 이상은 무통장입금만 가능합니다.
                    </div>
                  </div>
                </div>
                <div className={"PaymentButtonArea"}>
                  <button onClick={ProcessPayment}>결제</button>
                </div>
              </>
            )}
            {invoice.status === 'WAITING_FOR_WIRE_TRANSFER' && (
              <div className={"Information"}>
                <h3>무통장 입금 예약이 완료되었습니다.</h3><br />
                <div className={"MainArea__row"}>
                  <div className={"MainArea__col"}>
                    계좌번호
                  </div>
                  <div className={"MainArea__col"}>
                    기업은행 106-049074-01-018 강민준
                  </div>
                </div>
                <div className={"MainArea__row"}>
                  <div className={"MainArea__col"}>
                    금액
                  </div>
                  <div className={"MainArea__col"}>
                    {invoice.price.toLocaleString()}원
                  </div>
                </div><br />
                <small>현금영수증/세금계산서 발행이 필요하신 경우 고객센터로 문의주세요.</small>
              </div>
            )}
            {invoice.status === 'WAITING_FOR_TRANSFER' && (
              <div className={"Information"}>
                <h3>가상계좌 신청이 완료되었습니다.</h3><br />
                <div className={"MainArea__row"}>
                  <div className={"MainArea__col"}>
                    계좌번호
                  </div>
                  <div className={"MainArea__col"}>
                    {vbankInfo?.bank_name} {vbankInfo?.bank_account} {vbankInfo?.bank_username}
                  </div>
                </div>
                <div className={"MainArea__row"}>
                  <div className={"MainArea__col"}>
                    금액
                  </div>
                  <div className={"MainArea__col"}>
                    {invoice.price.toLocaleString()}원
                  </div>
                </div>
              </div>
            )}
            {invoice.status === 'PAID' && (
            <div className={"Information"}>
              <h3>결제가 완료되었습니다.</h3><br />
              <div className={"MainArea__row"}>
                이 창을 닫고 이전 페이지로 돌아가세요.
              </div>
            </div>
          )}
          </div>
        )}
      </div>
      <div className={"Footer"}>
        <p>이용약관 | 개인정보취급방침</p>
        클라우드스퀘어 | 대표자 : 강민준 | 사업자등록번호 : 856-65-00187<br/>
        032-710-0670 | 서울시 구로구 경인로20나길 30 A802호 | billing@cloudsquare.kr
      </div>
    </>
  );
};

export default App;
